<template>
  <div class="popup" v-if="isOpen" @click="closePopup">
    <div class="popup-content">
      <img class="close-icon" @click="closeModal" :src="icon">
      <p class="text-center text-tp-yellow font-bold text-2xl pt-10">
        ¿En qué consiste el Ranking?
      </p>
      <div class="flex flex-col gap-4 text-tp-yellow pt-14 px-5">
        <p v-for="(paragraph, index) in paragraphs" :key="index">
          <strong>Paso {{ index + 1 }}:</strong> {{ paragraph }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import icon from '@/assets/images/close-icon.png';

export default {
  name: 'InfoPopup',
  props: {
    isOpen: Boolean,
    paragraphs: Array
  },
  setup(props, { emit }) { 
    const closePopup = () => {
      emit('closePopup');
    }

    return {
      icon,
      closePopup
    }
  }
}
</script>

<style scoped>
.popup {
  position: fixed;
  z-index: 11;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.9);
}

.popup-content {
  margin: 5% auto;
  display: block;
  max-width: 90%;
  max-height: 80%;
}

.close-icon {
  float: right;
  width: 20px;
  height: 20px;
}

.close-icon:hover,
.close-icon:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
